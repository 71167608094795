import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./actions/Login";
import Keycloak from "keycloak-js";

function PropsRoute(props) {
  const { component: Component, ...rest } = props;

  let loginKeycloack = (e) => {
    e.preventDefault();
    // const keycloak = Keycloak(process.env.REACT_APP_BOUNCERCONFIG_URL);
    // let logout = localStorage.getItem("logout");
    // keycloak
    //   .init({
    //     onLoad: logout == "false" ? "login-required" : "",
    //     checkLoginIframe: false,
    //   })
    //   .then((authenticated) => {
    //     console.log("logout", keycloak);
    //     props.changeLoginValues(
    //       "authenticateduser",
    //       keycloak.tokenParsed.preferred_username
    //     );
    //     props.changeLoginValues("user", {
    //       ...props.loginDetails.user,
    //       keycloakAccessToken: keycloak.accessToken,
    //       firstname: keycloak.tokenParsed.given_name,
    //       lastname: keycloak.tokenParsed.family_name,
    //       email: keycloak.tokenParsed.preferred_username,
    //     });
    //     props.changeLoginValues("keycloak", keycloak);
    //     props.changeLoginValues("authenticated", authenticated);
    //   })
    //   .catch(() => {
    //     alert("failed to initialize");
    //   });
  };
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} loginKeycloack={loginKeycloack} />
      )}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropsRoute);
