import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Shell,
  VideoPlayer,
  FeatureArtists,
  Grid,
  Row,
  Column,
  Text,
  Button,
  BouncerContextHook,
  SisticLivePlayerAlt,
  EnterPin,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import Moment from "react-moment";
import Zadie from "../assets/images/Zadie.png";
import Book from "../assets/images/book.png";
// import BookStore from "../assets/images/bookstore.jpg";
// import Donation from "../assets/images/donation.png";
import Arrow from "../assets/images/leftarrow.png";
import ChatIco from "../assets/images/chat.png";
import QAIco from "../assets/images/Q&A.png";
import wrapimage from "../assets/images/wrap_bgimage.jpg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import Keycloak from "keycloak-js";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as VideoOnDemand from "../actions/VideoOnDemand";
import history from "../stores/history";
import DonationLogo from "../assets/images/giving_image.png";
import BookStore from "../assets/images/swfbook.png";

function VideoOnDemandDetail(props) {
  const loginContext = BouncerContextHook();
  // let onClose = () => {
  //   props.changeTransactionValues("productPrice", 0);
  //   props.changeTransactionValues("showDropinComponent", false);
  //   props.changeTransactionValues("transactionStatus", 1);
  //   props.changeTransactionValues("checkconsent", false);
  //   props.changeTransactionValues("isOpen", false);
  //   localStorage.setItem("buttonClicked", false);
  // };

  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
  };
  const filterVideoAssets = (id) => {
    let {
      videoOnDemand = {
        videoOnDemandList: [],
      },
    } = props;
    let data = videoOnDemand.videoOnDemandList.filter(
      (programme) => programme.id == id
    );
    return data;
  };
  useEffect(() => {
    let progammeId = props.match.params.id;
    props.fetchVideoOnDemandDetail(progammeId);
    return () => {
      props.changeVideoOnDemandValues("videoOnDemandDetail", []);
    };
  }, []);

  const noPass = () => {
    return !props.transactionDetails.passName ? true : false;
  };

  return (
    <>
      {props.videoOnDemand.videoOnDemandDetail.length > 0 &&
        props.videoOnDemand.videoOnDemandDetail.sort().map((value, key) => {
          return (
            <div className="row vodContainer adjustment">
              <div className="container">
                <Link
                  to={
                    localStorage.getItem("prevPath")
                      ? localStorage.getItem("prevPath")
                      : "/"
                  }
                  className="title"
                >
                  <img src={Arrow} alt="icon" />
                  <Text as="captionblock" className="titletext">
                    {ReactHtmlParser(
                      value.title.replace(/(?:\r\n|\r|\n)/g, "<br />")
                    )}
                    <div>
                      <Moment format="hh:mm A">{value.startTime}</Moment> -{" "}
                      <Moment format="hh:mm A">
                        {moment(value.startTime)
                          .add(value.duration, "m")
                          .toDate()}
                      </Moment>
                    </div>
                  </Text>
                </Link>
                <div className="wholediv">
                  <div className="videodiv voddiv disablechat">
                    {props.loginDetails.authenticated && noPass() == false ? (
                      <>
                        <EnterPin
                          domain={
                            process.env.REACT_APP_NODE_ENV == "development"
                              ? process.env.REACT_APP_TRANSACTION_API_DOMAIN
                              : ""
                          }
                          loginDetails={props.loginDetails}
                          isR21={value.r21}
                        >
                          <SisticLivePlayerAlt
                            className="child"
                            bgColor="white"
                            color="black"
                            playerItems={{
                              src: value.videoURL,
                              stopChat: true,
                              enableChat: false,
                              // chatContent: (
                              //   <LiveChat session={props.match.params.id} />
                              // ),
                            }}
                          />
                        </EnterPin>
                      </>
                    ) : (
                      ""
                    )}
                    {/* {props.loginDetails.authenticated === true &&
                    props.transactionDetails.hideBuyButton === false ? (
                      <div className="wrap_div">
                        <div className="button-group">
                          <Text as="h4" className="wrap_title">
                            {
                              "This video is only available upon login with your Access Pass"
                            }
                            <br />
                          </Text>
                          <div>
                            <Text as="h6" className="wrap_pass">
                              {"Don't Have an Access Pass yet?"}
                              <br />
                            </Text>
                            <Button
                              primary
                              className="video_btn"
                              onClick={(e) => {
                                getDegitalPass(e);
                              }}
                            >
                              Get your Access Pass here
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {props.loginDetails.authenticated !== true || noPass() ? (
                      <div
                        className="wrap_div"
                        style={{
                          backgroundImage: `url(${wrapimage})`,
                        }}
                      >
                        {/* <div className="button-group">
                          <div>
                            <Text as="h4" className="wrap_title">
                              This video is only available upon login
                              <br />
                            </Text>
                          </div>
                          <div>
                            <Text as="h6" className="wrap_pass">
                              Already have a pass?
                            </Text>
                            <Button
                              primary
                              className="video_btn"
                              onClick={(e) => {
                                getDegitalPass(e);
                              }}
                            >
                              Click here to login
                            </Button>
                          </div>
                        </div> */}
                        <Text as="h4" className="wrap_title">
                          This programme is only accessible with a Festival
                          Pass, Festival Pass Plus, or Video-On-Demand Pass.
                        </Text>
                        <div>
                          <Text as="h6" className="wrap_pass">
                            If you've purchased one, please log in with your
                            SISTIC account to view the programme. Get your
                            Video-On-Demand Pass from 18 Nov onwards.
                          </Text>
                          <Text as="h6" className="wrap_pass">
                            Get your Video-On Demand Pass here
                          </Text>
                          <Button
                            primary
                            className="video_btn"
                            onClick={(e) => {
                              getDegitalPass(e);
                            }}
                          >
                            Buy Now
                          </Button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="synopiscontainer">
                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="p" className="synopsis">
                      Synopsis
                    </Text>
                    <Text as="p">
                      {ReactHtmlParser(
                        value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                      )}
                    </Text>
                    <div className="bookwebsection">
                      <div className="booksection">
                        {value.bookstoreLink && (
                          <a
                            href={`https://swfbooks.com`}
                            target="_blank"
                            style={{
                              display: "flex",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              src={BookStore}
                              alt="icon"
                              style={{ width: "118px", height: "118px" }}
                            />
                            <Text as="p" className="booktext">
                              Visit <br /> swfbooks.com
                            </Text>
                          </a>
                        )}

                        {value.donationFlag && (
                          <a
                            href={`https://www.giving.sg`}
                            target="_blank"
                            style={{
                              display: "flex",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              src={DonationLogo}
                              alt="icon"
                              style={{
                                width: "118px",
                                height: "118px",
                              }}
                            />
                            <Text as="p" className="booktext">
                              Click here to donate <br />
                              to our giving.sg
                            </Text>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="chatsection">
                    <div className="videoDetailFeature">
                      {/* <Text as="h6">
                        <b>Featured Authors/Presenters</b>
                      </Text> */}
                      <Text as="p">
                        {value.featuredPresenters
                          ? ReactHtmlParser(
                              value.featuredPresenters.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              )
                            )
                          : null}
                      </Text>
                    </div>
                    <div className="videoDetailFeature">
                      {/* <Text as="h6">
                        <b>Moderator</b>
                      </Text> */}
                      <Text as="p">
                        {value.moderator
                          ? ReactHtmlParser(
                              value.moderator.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              )
                            )
                          : null}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* <TransactionStatus
      isOpen={props.transactionDetails.isOpen}
      onClose={onClose}
      transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
      transactionData={props.transactionDetails.transactionData}
      username={props.loginDetails.keycloak}
    /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeVideoOnDemandValues: (f, e) =>
    dispatch(VideoOnDemand.changeVideoOnDemandValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchVideoOnDemandDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchVideoOnDemandDetail(progammeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoOnDemandDetail);
