import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Text,
  Button,
  DropInPayment,
  Checkbox,
  RadioButton,
} from "@StixNext/mojito-ui";

import main from "../../assets/images/main.jpg";
import loading from "../../assets/images/loading.gif";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import ReactHtmlParser from "react-html-parser";

function Transaction(props) {
  const [braintreeError, setBraintreeError] = useState({});
  const hanldePayment = (cardPayload) => {
    props.checkoutTransaction(
      cardPayload,
      props.loginDetails.user,
      props.productDetails.selectedProduct,
      false
    );
  };
  useEffect(() => {
    document.getElementById("1").checked = true;
  }, []);
  const transactionError = (err) => {
    setBraintreeError(err);
  };
  return (
    <>
      <div>
        <Text as="h4" className="transaction_header">
          You Are Buying{" "}
          {ReactHtmlParser(
            props.productDetails.selectedProduct.title.replace(
              /(?:\r\n|\r|\n|<p>|<\/p>)/g,
              ""
            )
          )}{" "}
          - ${props.transactionDetails.productPrice}
        </Text>
      </div>
      <div className="transaction_box">
        <Text as="p" className="transaction_head_para">
          Selected sessions in this festival may require viewers discretion.
          Please select one of the options :
        </Text>
        <div className="confirm_radio">
          <RadioButton
            labelText={"I confirm I am 16 and above"}
            value={"1"}
            name="radio"
            id={"1"}
          ></RadioButton>

          <RadioButton
            labelText={
              "I am below 16 and am aware that parental guidance may be required for selected programmes"
            }
            value={"2"}
            name="radio"
            id={"2"}
          ></RadioButton>
        </div>
        {/* {braintreeError && (
          <div style={{ color: "red" }}>{braintreeError.message}</div>
        )} */}

        {/* <Checkbox
            id="agecheckbox"
            checked={props.transactionDetails.checkAge}
            onChange={(e) => {
              props.changeTransactionValues("checkAge", e.target.checked);
            }}
          >
            {" "}
          </Checkbox>{" "}
          <span>I am 16 or older</span> */}
      </div>
      <div
        className="loader-pro"
        style={{
          width: "100%",
          display: props.transactionDetails.loader,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <img
            className="img-fluid"
            style={{ width: "190px", height: "auto" }}
            src={loading}
            alt="Avatar"
          />
        </div>
      </div>
      <div
        className="dropPayment"
        style={{
          width: "100%",
          display: props.transactionDetails.paymentLoader,
        }}
      ></div>
      <div className={"drop-in-payment alignment"}>
        {props.transactionDetails.showDropinComponent ? (
          <DropInPayment
            authorizationToken={props.loginDetails.authToken}
            handlePaymentMethod={hanldePayment}
            onError={transactionError}
            buttonText="Proceed to Payment"
            transactionFailed={props.transactionDetails.transactionSuccessFlag}
            classProp="dropPayment"
            showConsent={true}
            checkedCheckbox={props.transactionDetails.checkconsent}
            amount={props.transactionDetails.productPrice}
            userInfo={props.loginDetails.user}
            isThreeDSecure={false}
            loaderFunction={() => {
              props.changeTransactionValues("loader", "none");
              props.changeTransactionValues("paymentLoader", "block");
            }}
            onChange={(e) => {
              props.changeTransactionValues("checkconsent", e.target.checked);
            }}
          />
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user, product, isThreeDSecure) =>
    dispatch(
      transActions.checkoutTransaction(
        cardPayload,
        user,
        product,
        isThreeDSecure
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
