import Programmes from '../../assets/json/programes.json'
import Workshop from '../../assets/json/workshop.json'
import Venue from '../../assets/json/venue.json'
import VideoOnDemand from '../../assets/json/videoOnDemand.json'

export const getProgrammes =()=>{
    return new Promise(function(resolve, reject) {
         resolve(Programmes)
    })  
}
export const getWorkshop =()=>{
    return new Promise(function(resolve, reject) {
         resolve(Workshop)
    })  
}
export const getVenue =()=>{
    return new Promise(function(resolve, reject) {
         resolve(Venue)
    })  
}
export const getVideoOnDemand =()=>{
    return new Promise(function(resolve, reject) {
         resolve(VideoOnDemand)
    })  
}