const init = {
  hideBuyButton: false,
  showDropinComponent: false,
  transactionSuccessFlag: null,
  isOpen: false,
  transactionData: null,
  checkconsent: false,
  checkAge: false,
  transactionStatus: 1,
  productPrice: 0,
  buttonClicked: false,
  loader: "none",
  paymentLoader: "block",
  amountbcmetadata: {},
  priceList: [
    {
      id: 1,
      text: "Regular Price",
      price: "20.00",
    },
    {
      id: 2,
      text: "Student / Senior / NSF",
      price: "12.00",
    },
  ],
  passName: "",
};

export const Transaction = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_TRANSACTION_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
