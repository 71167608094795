import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text } from "@StixNext/mojito-ui";
import Moment from "react-moment";

import banner from "../../assets/images/swfkv_banner.jpg";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import ReactHtmlParser from "react-html-parser";
import decode from "decode-html";

function TransactionSuccess(props) {
  return (
    <>
      <Grid>
        <Row>
          <img src={banner} alt="icon" className="success_img" />
        </Row>
        <div className="success_section">
          <Row>
            <Column xl={12}>
              <Text as="h6" className="thank_txt">
                Thank you. You have purchased
              </Text>
            </Column>
          </Row>
          <Row style={{ justifyContent: "space-between", width: "100%" }}>
            <Column xl={9} sm={12}>
              <Text as="h6" className="label_txt">
                {props.transactionDetails.transactionData &&
                props.transactionDetails.transactionData.product.title
                  ? ReactHtmlParser(
                      decode(
                        props.transactionDetails.transactionData.product.title.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br />"
                        )
                      )
                    )
                  : ""}
                {/* {"SWF2021 Festival Pass Plus - Standard"} */}
              </Text>
              <Text as="captionblock" className="confirm_section">
                {/* {"A confirmation e-mail will be sent to you within 48 hours"} */}
                {props.transactionDetails.transactionData
                  ? "A confirmation e-mail will be sent to you within 48 hours"
                  : ""}
              </Text>
            </Column>
            <Column xl={2} className="price_section_web">
              <Text as="h3">
                <strong>
                  S$
                  {props.transactionDetails.transactionData
                    ? props.transactionDetails.transactionData.payment_amount
                    : ""}
                </strong>
              </Text>
            </Column>
          </Row>
          <Row style={{ justifyContent: "space-between", width: "100%" }}>
            {/* <Column size={2}>
              <Text as="captionblock" style={{ fontWeight: "100" }}>
                {props.transactionDetails.transactionData
                  ? "A confirmation e-mail will be sent to you within 48 hours"
                  : ""}
              </Text>
            </Column> */}
            <Column size={1} className="price_section_mob">
              <Text as="h3">
                <strong>
                  S$
                  {props.transactionDetails.transactionData
                    ? props.transactionDetails.transactionData.payment_amount
                    : ""}
                </strong>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column xl={12}>
              <Text as="h6" className="thank_txt">
                Booking Reference
              </Text>
            </Column>
          </Row>
          <Row>
            <Column xl={3} sm={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <Text as="Captionblock">Transaction ID</Text>
              </Row>
              <Text as="Captionblock">
                {props.transactionDetails.transactionData
                  ? props.transactionDetails.transactionData.reference_id
                  : ""}
              </Text>
            </Column>
            <Column xl={3} sm={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <Text as="Captionblock">Date Of Purchase</Text>
              </Row>
              <Text as="Captionblock">
                {props.transactionDetails.transactionData ? (
                  <Moment
                    parse="DD/MM/YYYY hh:mm:ss a"
                    format="DD MMMM YYYY, h:mm a"
                  >
                    {props.transactionDetails.transactionData.txn_time}
                  </Moment>
                ) : (
                  ""
                )}
              </Text>
            </Column>
            <Column xl={3} sm={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <Text as="Captionblock">Name</Text>
              </Row>
              <Text as="Captionblock" className="tranform_txt">
                {props.loginDetails.keycloak
                  ? `${props.loginDetails.keycloak.tokenParsed.given_name} ${props.loginDetails.keycloak.tokenParsed.family_name}`
                  : ""}
              </Text>
            </Column>
            {/* <Column xl={3} sm={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <Text as="Captionblock">Account No.</Text>
              </Row>
              <Text as="Captionblock" className="tranform_txt">
                {props.loginDetails.keycloak
                  ? `${props.loginDetails.keycloak.tokenParsed.given_name} ${props.loginDetails.keycloak.tokenParsed.family_name}`
                  : ""}
              </Text>
            </Column> */}
          </Row>
        </div>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSuccess);
