export const transactionapi = `${process.env.REACT_APP_TRANSACTION_API_DOMAIN}${process.env.REACT_APP_TRANSACTION_API_PATH}`;
export const cms_api = `${process.env.REACT_APP_CMS_API_DOMAIN}/api/contents?tenant=${process.env.REACT_APP_CLIENTID}&collection=`;

export const api = {
  accessToken: `${transactionapi}/payments/braintree/token`,
  checkout: `${transactionapi}/payments/braintree/checkout`,
  fetchTnxn: `${transactionapi}/transactions/fetchPurchaseByUser`,
  products: `${transactionapi}/products/byClient`,
  gpurchase: `${transactionapi}/offers/redeem`,
  fetchCmsProducts: `${cms_api}products`,
  fetchWorkshopProgrames: `${cms_api}Programes&category=Workshops`,
  fetchInVenueProgrames: `${cms_api}Programes&category=In-Venue-Programmes`,
  fetchVODProgrames: `${cms_api}Programes&category=Video-on-Demand`,
  fetchFestivalPrograms: `${cms_api}Programes&passType=Festival%20Pass`,
  fetchFestivalPlusPrograms: `${cms_api}Programes&passType=Festival%20Pass%20Plus`,
  fetchVODPlacehorder: `${cms_api}placeholder&id=SWF2021-VOD`,
  fetchFpPlacehorder: `${cms_api}placeholder&id=SWF2021-FP`,
  fetchFppPlacehorder: `${cms_api}placeholder&id=SWF2021-FPP`,
  fetchWsPlacehorder: `${cms_api}placeholder&id=SWF2021-WS`,
  fetchIvpPlacehorder: `${cms_api}placeholder&id=SWF2021-IVP`,
};
