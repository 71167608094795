import moment from "moment";

const init = {
  festivalItems: [],
  festivalPlusItems: [],
  dateSelected: "",
  programme: []
};

export const Festival = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_FESTIVAL_ITEMS":
      return { ...state, [action.field]: action.value };
    case "UPDATE_FESTIVAL_PLUS_ITEMS":
      return { ...state, [action.field]: action.value };
      case "UPDATE_PROGRAMMES_VALUE":
        return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
