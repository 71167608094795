import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { deviceDetect } from "react-device-detect";
import { matchPath } from "react-router";
import queryString from "query-string";

import ScrollTop from "./ScrollTop";
import Overview from "./components/Overview";
import Schedule from "./components/Schedule";
import Workshops from "./components/Workshops";
import Livestream from "./components/Livestream";
import VideoDetail from "./components/VideoDetail";
import InVenueProgrammes from "./components/InVenueProgrammes";
import VideoOnDemand from "./components/VideoOnDemand";
import FestivalPass from "./components/FestivalPass";
import FestivalPassPlus from "./components/FestivalPassPlus";
import VideoOnDemandDetail from "./components/VideoOnDemandDetail";
import { FooterContent } from "./components/FooterUtil";

import {
  Shell,
  BouncerContext,
  BouncerContextHook,
  BouncerContextProvider,
} from "@StixNext/mojito-ui";
import Header from "./components/common/Header";
import * as actions from "./actions/Login";
import Keycloak from "keycloak-js";
import PropsRoute from "./PropsRoute";
import { SessionEndModal } from "./components/SessionEndModal";
import { useKeycloak } from "@react-keycloak/web";
import { useGlobalWorker } from "./worker";
import HiddenTransaction from "./components/HiddenTransaction";
// import { getUserSession } from "./firebase/utils";

const Routes = (props) => {
  const { history, location = {}, session } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const keycloakJs = Keycloak(process.env.REACT_APP_BOUNCERCONFIG_URL);
  // const { session_worker, multipleSession } = useGlobalWorker(session);

  let updateUser = (value) => {
    props.changeLoginValues("user", value);
  };
  const [keycloak, initialized] = useKeycloak();
  const valueBouncer = BouncerContextHook();

  useEffect(() => {
    if (
      !(
        location.pathname.includes("/festivalpassplus/") ||
        location.pathname.includes("/festivalpass/") ||
        location.pathname.includes("/workshops/") ||
        location.pathname.includes("/video-on-demand/") ||
        location.pathname.includes("/in-venue-programmes/") ||
        location.pathname.includes("/voddetail/")
      )
    ) {
      localStorage.setItem("prevPath", location.pathname);
    }

    if (!location.pathname.includes("/grouppass")) {
      localStorage.removeItem("grouppassid");
      localStorage.removeItem("grouppass");
      localStorage.removeItem("grouppasslogin");
      localStorage.removeItem("grouppasscompleted");
    }
  }, [location.pathname]);

  const value = { user: { ...props.loginDetails.user }, updateUser };
  let finalKeycloak = initialized ? keycloak : keycloakJs;

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      let sessionId = props.loginDetails.keycloak.sessionId;
      let userId = props.loginDetails.user.userid;
      // session_worker.startSession(userId, sessionId);
    }
  }, [props.loginDetails.authenticated]);

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      const { keycloak, user } = props.loginDetails;
      const { userid, email } = user;
      const { sessionId } = keycloak;

      const timestamp = +new Date();
      const useragent = deviceDetect();

      const userSession = {
        userid,
        email,
        sessionId,
        timestamp,
        useragent,
      };

      // try {
      //   getUserSession.push(userSession);
      // } catch (e) {
      //   console.log(e);
      // }
    }
  }, [props.loginDetails.authenticated]);

  // useEffect(() => {
  //   if (multipleSession) {
  //     setModalOpen(true);
  //     setTimeout(() => props.loginDetails.keycloak.logout(), 5000);
  //   }
  // }, [multipleSession]);

  const closeModal = () => {
    setModalOpen(false);
  };
  if (!initialized) {
    return <div>Loading...</div>;
    //   <div className="App">
    //   <div className="Loadercenter">
    //     <div className="loaderContent">
    //       <div className="loaderImg"></div>
    //       {/* <img src={main1} className="loaderImg"/> */}
    //       <div class="spinner">
    //         <span className="LoadingText">Loading</span>
    //         <div class="bounce1"></div>
    //         <div class="bounce2"></div>
    //         <div class="bounce3"></div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  }
  return (
    <Fragment>
      <SessionEndModal isOpen={modalOpen} onClose={closeModal} />
      <ScrollTop />
      <BouncerContextProvider keycloakJson={finalKeycloak} loginRequired={true}>
        <Shell value={value}>
          <Header history={history} location={location} />
          <Switch>
            <Route exact path="/" component={Overview} />
            <Route exact path="/overview" component={Overview} />
            <Route
              exact
              path="/grouppass"
              render={() => {
                let grouppassid = queryString.parse(location.search).id;
                localStorage.setItem("grouppassid", grouppassid);
                localStorage.setItem("grouppass", true);
                localStorage.setItem("grouppasslogin", true);
                return <Overview />;
              }}
            />
            <Route
              exact
              path="/report/grouppass"
              render={() => {
                let grouppassid = queryString.parse(location.search).id;
                localStorage.setItem("grouppassid", grouppassid);
                localStorage.setItem("grouppass", true);
                localStorage.setItem("grouppasslogin", true);
                return <Overview />;
              }}
            />
            <Route
              exact
              path="/festivalpassplus"
              component={FestivalPassPlus}
            />
            <Route exact path="/festivalpass" component={FestivalPass} />
            <Route exact path="/workshops" component={Workshops} />
            <Route exact path="/video-on-demand" component={VideoOnDemand} />
            <Route
              exact
              path="/in-venue-programmes"
              component={InVenueProgrammes}
            />
            <Route exact path="/festivalpass/:id" component={VideoDetail} />
            <Route exact path="/workshops/:id" component={VideoDetail} />
            <Route
              history={history}
              exact
              path="/livevideo/:id"
              component={VideoDetail}
            />
            <Route exact path="/festivalpassplus/:id" component={VideoDetail} />
            <Route
              exact
              path="/voddetail/:id"
              component={VideoOnDemandDetail}
            />
            <Route
              exact
              path="/hiddentransaction"
              component={HiddenTransaction}
            />
            <Route path="*" render={() => <Redirect to="/overview" />} />
          </Switch>
          <Shell.Footer
            className="appFooter"
            footerItems={{ FooterComponent: FooterContent }}
          />
        </Shell>
      </BouncerContextProvider>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
