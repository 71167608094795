import { theme } from "@StixNext/mojito-ui";

theme.mojito.colors.primaryBlue = "#02c09e";
theme.mojito.colors.primaryHover = "#02c09e";
theme.mojito.colors.primaryActive = "#02c09e";
theme.mojito.colors.primaryDisabled = "#02c09e";
theme.mojito.colors.checkBoxBorderColor = "#02c09e;";
theme.mojito.colors.checkBoxBackgroundColor = "#02c09e;";
theme.mojito.colors.disabledText = "#FFFFFF";
theme.mojito.buttonBorder.buttonBorderRadius = "0px";
theme.mojito.opacities.checkboxOpacity = "0";
theme.mojito.colors.primaryRed = "white";
theme.mojito.colors.primaryModalBackground = "rgba(0, 0, 0, 0.81)";
theme.mojito.colors.primarySalmon = "#ffffff";

export { theme };
