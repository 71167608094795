import React from "react";
import { Text } from "@StixNext/mojito-ui";

function showVideo(displayStartTime, displayEndTime) {
  let start = new Date(displayStartTime);
  let end = new Date(displayEndTime);
  let current = new Date();
  return current > start && current < end;
}

export function displayFilter(array) {
  return array.filter((item) =>
    showVideo(item.displayStartTime, item.displayEndTime)
  );
}

export function formatDate(date) {
  let options = { year: "numeric", month: "short", day: "numeric" };
  let [month, day, year] = new Date(date)
    .toLocaleDateString(undefined, options)
    .replace(",", "")
    .split(" ");
  return `${day} ${month} ${year}`;
}

export function formatDateTime(date) {
  let [newDate, time] = new Date(date).toLocaleString("en-US").split(",");
  let [hours, minutes, m] = time.split(":");
  let [_, meridiem] = m.split(" ");
  return `${formatDate(newDate)}, ${hours}:${minutes} ${meridiem}`;
}

export function getSchedulerData(data, page) {
  return data.map(
    ({
      startTime,
      duration,
      channelId,
      venue,
      featuredPresenters,
      writeUp,
      qnaFlag,
      ...rest
    }) => ({
      date: new Date(startTime).toLocaleString("en-SG", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      }),
      startDateTime: startTime,
      startTime: new Date(startTime)
        .toLocaleString("en-US", {
          hour: "2-digit",
          hour12: false,
          minute: "2-digit",
        })
        .replace(":", ""),
      endTime: new Date(new Date(startTime).getTime() + duration * 60000)
        .toLocaleString("en-US", {
          hour: "2-digit",
          hour12: false,
          minute: "2-digit",
        })
        .replace(":", ""),
      channelId:
        page === "workshops"
          ? channelId
          : page === "festivalPass"
          ? channelId
          : venue === "Malay Heritage Centre Auditorium"
          ? 1
          : venue === "Projector x Riverside"
          ? 2
          : venue === "The Arts House Chamber"
          ? 3
          : venue === "The Arts House Living Room"
          ? 4
          : venue === "The Arts House Play Den"
          ? 5
          : venue === "The Fabulous Baker Boy"
          ? 6
          : venue === "The Projector"
          ? 7
          : venue === "Theatre Practice Rehearsal Studio"
          ? 8
          : venue === "Various Locations"
          ? 9
          : "",

      featuredPresenters: "",
      writeUp: "",
      venue,
      duration,
      subTitle: qnaFlag ? "Live Q&A" : "",
      ...rest,
    })
  );
}

export function passInfo(type) {
  return (
    <div className="pass-info">
      <Text as="h6">
        <strong>{`${type} Redemption for Festival Pass / Festival Pass Plus Holders`}</strong>
      </Text>
      <Text as="p">
        If you have just purchased a Digital Festival Pass, please take note of the following:
      </Text>
      <ul>
        <li>
          {`You will be redirected to the SISTIC website to redeem your ${type} tickets.`}
        </li>
        <li>
          Kindly note that this is on a first-come-first-serve basis.
        </li>
        <li>
          An email confirmation will be sent to you after you have successfully registered for the programmes.
        </li>
        <li>
          Please use your e-ticket to gain entry into the venue on event days.
        </li>
      </ul>
      <Text as="p" className="sgt">
        Date & Time of programmes are listed based on Singapore Time.
      </Text>
    </div>
  )
}
