import { api } from "./commonapi/apiPaths";
import { getVideoOnDemand } from "./commonapi/mockData";

export const changeVideoOnDemandValues = (field, value) => {
  return {
    type: "UPDATE_VIDEOONDEMAND_VALUE",
    field,
    value,
  };
};

export const fetchVideoOnDemand = () => {
  return (dispatch) => {
    return fetch(api.fetchVODProgrames)
      .then((data) => data.json())
      .then((value) => {
        dispatch(changeVideoOnDemandValues("videoOnDemandList", value.data));
      })
      .catch((err) => {});
  };
};

export const fetchVideoOnDemandDetail = (progammeId) => {
  const collection =
    localStorage.getItem("prevPath") === "/festivalpassplus"
      ? api.fetchFestivalPlusPrograms
      : localStorage.getItem("prevPath") === "/festivalpass"
      ? api.fetchFestivalPrograms
      : localStorage.getItem("prevPath") === "/workshops"
      ? api.fetchWorkshopProgrames
      : localStorage.getItem("prevPath") === "/in-venue-programmes"
      ? api.fetchInVenueProgrames
      : localStorage.getItem("prevPath") === "/video-on-demand"
      ? api.fetchVODProgrames
      : "";
  return (dispatch) => {
    return fetch(collection)
      .then((data) => data.json())
      .then((data) => {
        let programmeDetail = data.data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(
          changeVideoOnDemandValues("videoOnDemandDetail", programmeDetail)
        );
      })
      .catch((err) => {});
  };
};

export const fetchVODPlaceholder = async () => {
  let details  = await fetch(api.fetchVODPlacehorder)
    .then((data) => data.json())
    .then(value => value.data)
    .catch((err) => {});
    return details 
}