import React from "react";
import { Shell } from "@StixNext/mojito-ui";

export default function ComingSoon({image}) {
  return (
    <Shell.Body className="shell_body">
      <div className="coming-soon">
        <img src={image} alt="icon" />
      </div>
    </Shell.Body>
  );
}