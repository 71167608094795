import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text } from "@StixNext/mojito-ui";
import Moment from "react-moment";

import banner from "../../assets/images/grouppass_success.jpg";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import ReactHtmlParser from "react-html-parser";
import decode from "decode-html";

function GroupPassSuccess(props) {
  return (
    <>
      <Grid>
        <div className="success_section">
          <Row>
            <Column xl={12} xs={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <img
                  src={banner}
                  alt="icon"
                  style={{ width: "100%", height: "auto" }}
                />
              </Row>
            </Column>
            <Row className="success_row">
              <Text as="h4" className="success_txt">
                Thank you
              </Text>
            </Row>
            <Row className="success_row">
              <Text as="h5" className="redeem_txt">
                You have redeemed your SWF2021 Video-On-Demand Pass!
              </Text>
            </Row>
          </Row>
        </div>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupPassSuccess);
