import { api } from "./commonapi/apiPaths";
import { getSchedulerData } from "../utils/utils";
import { changeProductValues } from "./Products";

export const changeFestivalValues = (field, value) => {
  return {
    type: "UPDATE_FESTIVAL_ITEMS",
    field,
    value,
  };
};

export const changeFestivalPlusValues = (field, value) => {
  return {
    type: "UPDATE_FESTIVAL_PLUS_ITEMS",
    field,
    value,
  };
};

export const changeProgrammesValues = (field, value) => {
  return {
    type: "UPDATE_PROGRAMMES_VALUE",
    field,
    value,
  };
};

export const fetchFestivalPrograms = () => {
  return (dispatch) => {
    fetch(api.fetchFestivalPrograms)
      .then((data) => data.json())
      .then((value) => {
        let programList = getSchedulerData(value.data, "festivalPass");
        dispatch(changeFestivalValues("festivalItems", programList));
      })
      .catch((err) => {});
  };
};

export const fetchFestivalPlusPrograms = () => {
  return (dispatch) => {
    fetch(api.fetchFestivalPlusPrograms)
      .then((data) => data.json())
      .then((value) => {
        dispatch(changeFestivalPlusValues("festivalPlusItems", value.data));
      })
      .catch((err) => {});
  };
};


export const fetchProgrammeDetail = (progammeId, pass) => {
  let url
  if (pass == "Workshops") {
    url = api.fetchWorkshopProgrames
  } else if (pass == "Festival Pass") {
    url = api.fetchFestivalPrograms
  } else {
    url = api.fetchFestivalPlusPrograms
  }
  return (dispatch) => {
    return fetch(url)
      .then((res) => res.json())
      .then((value) => {
        let programmeDetail = value.data.filter((item) => {
          return item.id == progammeId
        });
        dispatch(changeProductValues("videoDetailId", progammeId));
        dispatch(changeProgrammesValues("programme", programmeDetail));
      })
      .catch((err) => {});
  };
};

export const fetchFpPlaceholder = async () => {
  let details  = await fetch(api.fetchFpPlacehorder)
    .then((data) => data.json())
    .then(value => value.data)
    .catch((err) => {});
    return details 
}

export const fetchFppPlaceholder = async () => {
  let details  = await fetch(api.fetchFppPlacehorder)
    .then((data) => data.json())
    .then(value => value.data)
    .catch((err) => {});
    return details 
}


export const setDateSelected = (date) => {
  return (dispatch) => {
    dispatch(changeFestivalValues("dateSelected", date));
  };
};
