import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import {
  Column,
  Grid,
  Row,
  Scheduler,
  RightArrowIcon,
  Dropdown,
} from "@StixNext/mojito-ui";
import * as actions from "../actions/Festival";
import { connect } from "react-redux";
import { displayFilter, formatDateTime } from "../utils/utils";
import ComingSoon from "./ComingSoon";
import Festival_pass_coming_soon from "../assets/images/festival_pass_coming_soon.gif";

const sortByProperty = (property) => {
  return (a, b) => {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
};

const options = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const FestivalPass = (props) => {
  let { fetchWorkshop, festivalItems, dateSelected, setDateSelected } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  let defaultChannel = [
    { channelName: "Channel 1", channelId: 1 },
    { channelName: "Channel 2", channelId: 2 },
    { channelName: "Channel 3", channelId: 3 },
  ];

  const [channel, setChannel] = useState(defaultChannel);
  const [mobchannel, setMobChannel] = useState(defaultChannel);
  const [dateOptions, setDateOptions] = useState([]);
  const [scheduleData, setScheduleData] = useState(festivalItems);
  const [slidesEvents, setSlidesEvents] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(
    defaultChannel[0].channelId
  );
  const [placeholder, setPlaceholder] = useState("");

  useEffect(() => {
    props.fetchFestivalPrograms();
    getPlaceholder();
  }, []);

  useEffect(() => {
    if (festivalItems.length != 0) {
      let dates = festivalItems.map((programmes) => programmes.date);
      let uniqueDates = dates.sort().filter((v, i, a) => a.indexOf(v) === i);
      uniqueDates = uniqueDates.map((date) => ({ text: date, value: date }));
      if (!dateSelected) {
        setScheduleFilter(uniqueDates[0].value);
      }
      setDateOptions(uniqueDates);
    }
  }, [festivalItems]);

  let timeLine = [
    { time: "10:00", endTime: "1100", startTime: "1000" },
    { time: "11:00", endTime: "1200", startTime: "1100" },
    { time: "12:00", endTime: "1300", startTime: "1200" },
    { time: "13:00", endTime: "1400", startTime: "1300" },
    { time: "14:00", endTime: "1500", startTime: "1400" },
    { time: "15:00", endTime: "1600", startTime: "1500" },
    { time: "16:00", endTime: "1700", startTime: "1600" },
    { time: "17:00", endTime: "1800", startTime: "1700" },
    { time: "18:00", endTime: "1900", startTime: "1800" },
    { time: "19:00", endTime: "2000", startTime: "1900" },
    { time: "20:00", endTime: "2100", startTime: "2000" },
    { time: "21:00", endTime: "2200", startTime: "2100" },
    { time: "22:00", endTime: "2300", startTime: "2200" },
    { time: "23:00", endTime: "2400", startTime: "2300" },
  ];

  const setScheduleFilter = (date) => {
    let data = festivalItems
      .sort(sortByProperty("date"))
      .filter((item) => item.date == date);
    setScheduleData(data);
    setSlidesEvents(data);
    setDateSelected(date);
  };

  useEffect(() => {
    setScheduleFilter(dateSelected);
  }, [dateSelected]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const handleClick = (eventId) => {
    props.history.push(`/festivalpass/${eventId}`);
  };

  useEffect(() => {
    handleChannelView();
  }, [selectedChannel]);

  const handleChannelView = () => {
    let filterChannel = defaultChannel.filter(
      (item) => item.channelId == selectedChannel
    );
    setMobChannel(filterChannel);
  };

  const getPlaceholder = async () => {
    const val = await actions.fetchFpPlaceholder();
    setPlaceholder(val);
  };

  const slidesPresented = () => {
    if (windowWidth <= 760) {
      return 1;
    } else if (windowWidth <= 1366) {
      return 2;
    }
    return 3;
  };

  if (slidesEvents.length == 0 && placeholder) {
    let image;
    if (placeholder.length == 0) {
      image = Festival_pass_coming_soon;
    } else {
      let imageArray = placeholder[0].placeholderImage
        ? placeholder[0].placeholderImage.split(",")
        : "";
      image = imageArray ? imageArray[1] : "";
    }
    return <ComingSoon image={image} />;
  }

  return (
    <>
      {slidesEvents.length > 0 && (
        <Shell.Body className={"css-work-shop"}>
          <div className="pass-info">
            <Text as="p">
              All programmes listed here can be accessed by both Festival Pass and Festival Pass Plus holders.
            </Text>
          </div>
          <>
            <Grid>
              <Row className="scheduler-class">
                <Column xl={12}>
                  <div id="dropdown_123" style={{ display: "flex" }}>
                    <h1
                      style={{
                        paddingTop: 6,
                        margin: "0px 15px 15px 15px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      className="schedule-title"
                    >
                      SCHEDULE
                    </h1>
                    <div>
                      <Dropdown
                        id="dropdown"
                        options={dateOptions}
                        mulitple={false}
                        Width={250}
                        value={dateSelected}
                        placeholder="Select a Date"
                        onChange={(item) => {
                          setDateSelected(item.value);
                        }}
                      />
                      <Dropdown
                        id="dropdown_channel"
                        options={defaultChannel.map((channel, index) => {
                          return {
                            text: channel.channelName,
                            value: channel.channelId,
                          };
                        })}
                        mulitple={false}
                        value={selectedChannel}
                        placeholder="Select a channel"
                        onChange={(item) => setSelectedChannel(item.value)}
                      />
                    </div>
                  </div>
                </Column>
              </Row>
              <Row>
                <Carousel
                  autoPlay={false}
                  interval={5000}
                  slidesPresented={slidesPresented()}
                  Indictor={windowWidth > 760 ? true : false}
                  DotIndictor={windowWidth > 760 ? true : false}
                  MaxIndictor={slidesEvents.length}
                  className={"carousel"}
                >
                  {slidesEvents
                    .sort(sortByProperty("startTime"))
                    .map((slide, index) => {
                      let imageArray = slide.thumbNail
                        ? slide.thumbNail.split(",")
                        : "";
                      let image = imageArray ? imageArray[1] : "";
                      return (
                        <Card
                          key={index}
                          className={"carousel-slides"}
                          style={{ width: `${100 / slidesPresented()}%` }}
                          onClick={() => handleClick(slide?.id)}
                          noPadding={false}
                          noBoxShadow={false}
                          noBorder={true}
                          noBorderRadius={true}
                        >
                          <img src={image} alt="" />
                          <div>
                            <div
                              className={"title-tiles vod-tiles"}
                              dangerouslySetInnerHTML={{
                                __html: slide?.title,
                              }}
                            />
                            <p as="p" style={{ fontSize: "14px" }}>
                              {"Premieres: " +
                                formatDateTime(slide.startDateTime)}{" "}
                            </p>
                          </div>
                        </Card>
                      );
                    })}
                </Carousel>
              </Row>
            </Grid>

            <Grid>
              <Row>
                <Column xl={12}>
                  <Scheduler
                    className="web_schedule"
                    scheduleData={scheduleData}
                    timeLine={timeLine}
                    channel={channel}
                    eventOnClick={handleClick}
                    titleKey={"title"}
                    subTitleKey={"featuredPresenters"}
                    defultTextButton={"Live Q&A "}
                  ></Scheduler>
                  <Scheduler
                    className="mob_invenue_schedule"
                    scheduleData={scheduleData}
                    timeLine={timeLine}
                    channel={mobchannel}
                    eventOnClick={handleClick}
                    titleKey={"title"}
                    subTitleKey={"featuredPresenters"}
                    defultTextButton={"Live Q&A "}
                  ></Scheduler>
                </Column>
              </Row>
            </Grid>
          </>
        </Shell.Body>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  festivalItems: displayFilter(state.Festival.festivalItems),
  dateSelected: state.Festival.dateSelected,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFestivalPrograms: () => dispatch(actions.fetchFestivalPrograms()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalPass);
