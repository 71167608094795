import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Shell,
  Menu,
  BouncerContextHook,
  B2CHeader,
  Grid,
  Column,
  Row,
  BouncerContext,
} from "@StixNext/mojito-ui";
import logo from "../../assets/images/logo.png";
import logomob from "../../assets/images/logomob.png";
// import Keycloak from "keycloak-js";
import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import { TransactionStatus } from "../BuyProductAndTransaction";
import _ from "lodash";

function Header(props) {
  const { history, location = {} } = props;
  const { pathname = "/" } = location;
  const [keycloak, setKeycloak] = useState({});
  const [closeModal, setCloseModal] = useState(false);
  const [vodName, setVodName] = useState("Video-On-Demand");

  const value = BouncerContextHook();
  let onClose = () => {
    props.changeTransactionValues("productPrice", 0);
    props.changeTransactionValues("showDropinComponent", false);
    props.changeTransactionValues("transactionStatus", 1);
    props.changeTransactionValues("checkconsent", false);
    props.changeTransactionValues("isOpen", false);
    props.changeTransactionValues("hideBuyButton", false);
    localStorage.setItem("buttonClicked", false);

    if (localStorage.getItem("grouppasslogin") == "true") {
      localStorage.setItem("grouppasslogin", false);
      localStorage.setItem("grouppass", false);
      localStorage.removeItem("grouppassid");
    }
    if (localStorage.getItem("grouppasscompleted") == "false") {
      props.changeTransactionValues("showDropinComponent", false);
      props.changeTransactionValues("checkconsent", false);
    }
    if (localStorage.getItem("r21")) {
      localStorage.removeItem("r21")
    }
  };
  if (
    Object.keys(value.keycloak).length > 0 &&
    Object.keys(keycloak).length < 1
  ) {
    setKeycloak(value.keycloak);
  } else if (
    Object.keys(value.keycloak).length > 0 &&
    Object.keys(keycloak).length > 0 &&
    !_.isEqual(keycloak, value.keycloak)
  ) {
    setKeycloak(value.keycloak);
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      props.changeLoginValues(
        "authenticateduser",
        keycloak.tokenParsed.preferred_username
      );
      props.changeLoginValues("user", {
        ...props.loginDetails.user,
        keycloakAccessToken: keycloak.token,
        firstname: keycloak.tokenParsed.given_name,
        lastname: keycloak.tokenParsed.family_name,
        username: keycloak.tokenParsed.preferred_username,
        email:
          keycloak.tokenParsed.email || keycloak.tokenParsed.preferred_username,
        userid: keycloak.tokenParsed.sub,
      });
      props.changeLoginValues("accessToken", keycloak.token);
      localStorage.setItem("dch_access_token", keycloak.token);
      props.changeLoginValues("keycloak", keycloak);
      props.changeLoginValues("authenticated", keycloak.authenticated);
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: "Login",
      //     userId: keycloak.tokenParsed.sub,
      //     firstname: keycloak.tokenParsed.given_name,
      //     lastname: keycloak.tokenParsed.family_name,
      //     email: keycloak.tokenParsed.email,
      //   },
      // });
    }
  }, [keycloak]);
  let getDegitalPass = async (e) => {
    setCloseModal(false);
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      setCloseModal(false);
      if (!props.loginDetails.authenticated) {
        value.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 500);
  };

  let logoutKeycloack = (e) => {
    e.preventDefault();
    props.loginDetails.keycloak.logout();
  };

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      // if (!props.loginDetails.authToken && props.loginDetails.accessToken) {
      //   props.getAuthToken();
      // }

      if (
        props.loginDetails.user.userid
        // &&
        // props.loginDetails.authToken &&
        // props.loginDetails.accessToken
      ) {
        if (localStorage.getItem("grouppasslogin") == "true") {
          props.grouppassTransaction(
            props.loginDetails.user,
            localStorage.getItem("grouppassid")
          );
        }
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
        //R21 <------------>
        let r21Type = localStorage.getItem("r21")
        if (r21Type) {
          props.r21UpdatePin(r21Type)
        }
      }
    } else {
      props.changeTransactionValues("hideBuyButton", false);
    }
  }, [
    props.loginDetails.authenticated,
    props.loginDetails.user.userid,
    // props.loginDetails.authToken,
    // props.loginDetails.accessToken,
  ]);

  useEffect(() => {
    value.transactionSuccessFlag =
      props.transactionDetails.transactionSuccessFlag;
  }, [props.transactionDetails.transactionSuccessFlag]);
  useEffect(() => {
    if (props.loginDetails.tokenExpired == "Token Expired") {
      value.loginKeycloak();
      props.changeLoginValues("tokenExpired", "");
      props.changeLoginValues("logout", true);
    }
    // if (props.loginDetails.logout) {
    //   value.loginKeycloak();
    // }
  }, [props.loginDetails.tokenExpired, props.loginDetails.logout]);

  const handleNavigation = (path) => (e) => {
    e.preventDefault();
    history.push(path);
  };

  // useEffect(() => {
  //   localStorage.setItem("buttonClicked", false);
  // }, []);

  const handleResize = () => {
    if (window.innerWidth <= 575) {
      setVodName("VOD");
    } else {
      setVodName("Video-On-Demand");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  //R21 <------------>

  useEffect(() => {
    let logout = localStorage.getItem("logout");
    if (logout == "true" && !props.loginDetails.authenticated) {
      localStorage.setItem("logout", "false");
      value.loginKeycloak();
    }
  }, []);
  
  let buyR21 = () => {
    props.changeTransactionValues("isOpen", true);
    props.changeTransactionValues("transactionStatus", 11)
  }

  return (
    <>
      <B2CHeader className="sticky-header">
        <B2CHeader.Banner className="SSO_header">
          <Grid>
            <Row>
              <Column>
                <span className="spantag">
                  <img
                    className="baselogo"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/");
                    }}
                    src={logo}
                    alt="image"
                  />
                  <img
                    className="moblogo"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/");
                    }}
                    src={logo}
                    alt="image"
                  />
                </span>
              </Column>
            </Row>
          </Grid>
        </B2CHeader.Banner>
        <B2CHeader.PoweredByBanner className="powered_by_tab">
          <B2CHeader.UserBuyPass
            closeModal={closeModal}
            updateCloseModal={setCloseModal}
            className="user_buypass"
          >
            <B2CHeader.PopOver>
              <B2CHeader.PopOverItem>
                {!value.sessionRestrictor &&
                props.loginDetails.authenticated &&
                props.transactionDetails.transactionSuccessFlag ? (
                  <div className="popover_success_passcard">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        paddingTop: "10px",
                      }}
                    >
                      {props.transactionDetails.passName}
                    </p>
                    <span
                      className="span_mail"
                      style={{ fontSize: "12px", paddingTop: "15px" }}
                    >
                      {props.loginDetails.keycloak.tokenParsed.email}
                    </span>
                  </div>
                ) : (
                  <div className="popover_passcard">
                    <span
                      className="span_mail"
                      style={{
                        paddingTop: "25px",
                        paddingBottom: "15px",
                      }}
                    >
                      You dont have access pass
                    </span>
                    <a
                      href="#"
                      onClick={getDegitalPass}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "none",
                      }}
                    >
                      Click here to buy now
                    </a>
                  </div>
                )}
                <hr />
              </B2CHeader.PopOverItem>
              <B2CHeader.PopOverItem>
                <a href="#"
                  onClick={buyR21}
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                 >
                  R21 Content Pin
                </a>
                <hr />
              </B2CHeader.PopOverItem>
              <B2CHeader.PopOverItem>
                <a
                  href="https://ticketing.sistic.com.sg/sistic/patron/management"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                  rel="noreferrer"
                  target="_blank"
                >
                  My Account
                </a>
              </B2CHeader.PopOverItem>
            </B2CHeader.PopOver>
          </B2CHeader.UserBuyPass>
        </B2CHeader.PoweredByBanner>
        <div className={"menu_list"}>
          <B2CHeader.Navigation className="nav-tabs">
            {pathname.includes("/livevideo") ||
            pathname.includes("/voddetail") ? (
              <div></div>
            ) : (
              <Menu
                items={[
                  {
                    menuItem: "Overview",
                    navigation: "/overview",
                    onClick: handleNavigation("/overview"),
                    active: pathname === "/overview" || pathname === "/",
                  },
                  {
                    menuItem: "Festival Pass Plus",
                    navigation: "/festivalpassplus",
                    onClick: handleNavigation("/festivalpassplus"),
                    active: pathname === "/festivalpassplus",
                  },
                  {
                    menuItem: "Festival Pass",
                    navigation: "/festivalpass",
                    onClick: handleNavigation("/festivalpass"),
                    active: pathname === "/festivalpass",
                  },
                  {
                    menuItem: "Workshops",
                    navigation: "/workshops",
                    onClick: handleNavigation("/workshops"),
                    active: pathname === "/workshops",
                  },
                  {
                    menuItem: " In-Venue Programmes ",
                    navigation: "/in-venue-programmes",
                    onClick: handleNavigation("/in-venue-programmes"),
                    active: pathname === "/in-venue-programmes",
                  },
                  {
                    menuItem: vodName,
                    navigation: "/video-on-demand ",
                    onClick: handleNavigation("/video-on-demand"),
                    active: pathname === "/video-on-demand",
                  },
                ]}
              ></Menu>
            )}
          </B2CHeader.Navigation>
        </div>
      </B2CHeader>
      <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
        transactionStatus={props.transactionDetails.transactionStatus}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  grouppassTransaction: (user, passid) =>
    dispatch(transActions.grouppassTransaction(user, passid)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  r21UpdatePin: (type) => dispatch(transActions.r21UpdatePin(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
