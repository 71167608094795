import { api } from "./commonapi/apiPaths";
import { headers } from "./commonapi/Headers";
import { ifTokenExpire } from "./commonapi/TokenValidation";
import { changeLoginValues } from "./Login";

export const changeTransactionValues = (field, value) => {
  return {
    type: "UPDATE_TRANSACTION_VALUE",
    field,
    value,
  };
};

export const fetchTransaction = (user, buttonClicked) => {
  var header = headers();
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.fetchTnxn, {
        headers: header,
        method: "POST",
        body: JSON.stringify({
          userid: user.userid,
          clientid: process.env.REACT_APP_CLIENTID,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data) {
            var tokenExiration = ifTokenExpire(data);
            if (tokenExiration) {
              dispatch(changeLoginValues("tokenExpired", "Token Expired"));
            } else {
              if (data.count > 0) {
                dispatch(changeTransactionValues("showDropinComponent", false));
                dispatch(changeTransactionValues("hideBuyButton", true));
                dispatch(
                  changeTransactionValues(
                    "passName",
                    data.transaction[0].product?.sessionIds
                      ?.split(",")
                      .includes("VOD")
                      ? "VOD PASS HOLDER"
                      : data.transaction[0].product_id
                          .toLowerCase()
                          .includes("plus")
                      ? "FESTIVAL PASS PLUS HOLDER"
                      : "FESTIVAL PASS HOLDER"
                  )
                );
                dispatch(
                  changeTransactionValues("transactionSuccessFlag", true)
                );
                localStorage.setItem("buttonClicked", false);
              } else if (data.count == "0") {
                if (buttonClicked == "true") {
                  dispatch(
                    changeTransactionValues("showDropinComponent", true)
                  );
                  dispatch(changeTransactionValues("isOpen", true));
                }
              }
            }
          }
        })
        .catch((err) => {});
    }
  };
};

export const r21UpdatePin = (type) => {
  return (dispatch) => {
    dispatch(changeTransactionValues("isOpen", true))
    type == "createpin" ? dispatch(changeTransactionValues("transactionStatus", 11))
    : dispatch(changeTransactionValues("transactionStatus", 12))
  }
}

export const checkoutTransaction = (
  cardPayload,
  user,
  product,
  isThreeDSecure
) => {
  var header = headers();
  const postPayload = cardPayload;
  postPayload.userid = user.userid;
  postPayload.productid = product.id;
  postPayload.amount = product.price;
  postPayload.deviceData = {};
  postPayload.productdesc = product.description;
  postPayload.maid = process.env.REACT_APP_MERCHANT_ACCID;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.isThreeDSecure = isThreeDSecure
    ? isThreeDSecure
    : process.env.REACT_APP_ISTHREEDSECURE;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.product = JSON.parse(
    JSON.stringify(product, (key, value) => {
      if (typeof value === "number") {
        return value + "";
      }
      if (typeof value === "boolean") {
        return value ? "true" : "false";
      }
      return value;
    })
  );
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.checkout, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(
              changeTransactionValues("transactionData", data.transaction[0])
            );

            dispatch(changeTransactionValues("transactionStatus", 3));
            dispatch(changeTransactionValues("isOpen", true));
            dispatch(changeTransactionValues("checkconsent", false));
            localStorage.setItem("buttonClicked", false);
            setTimeout(() => {
              dispatch(fetchTransaction(user, false));
            }, 2000);
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("checkconsent", false));
            dispatch(changeTransactionValues("transactionStatus", 4));
            dispatch(changeTransactionValues("isOpen", true));
            setTimeout(() => {
              dispatch(fetchTransaction(user, false));
            }, 2000);
          }
        });
    }
  };
};

export const grouppassTransaction = (user, passid) => {
  var header = headers();
  const postPayload = {};
  postPayload.code = passid;
  postPayload.clientid = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.gpurchase, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            if (data?.transaction?.length > 0) {
              localStorage.setItem("grouppasslogin", false);
              localStorage.setItem("grouppass", false);
              localStorage.removeItem("grouppassid");
              localStorage.setItem("grouppasscompleted", true);
              dispatch(changeTransactionValues("transactionSuccessFlag", true));
              dispatch(changeTransactionValues("transactionStatus", 6));
              dispatch(
                changeTransactionValues("transactionData", data.transaction[0])
              );
              dispatch(changeTransactionValues("isOpen", true));
              setTimeout(() => {
                dispatch(fetchTransaction(user, false));
              }, 2000);
            } else {
              dispatch(changeTransactionValues("transactionStatus", 7));
              dispatch(
                changeTransactionValues("gpurchaseFailureMessage", data.message)
              );
              localStorage.setItem("grouppasslogin", false);
              localStorage.setItem("grouppass", false);
              localStorage.removeItem("grouppassid");
              localStorage.setItem("grouppasscompleted", true);
              dispatch(changeTransactionValues("isOpen", true));
              setTimeout(() => {
                dispatch(fetchTransaction(user, false));
              }, 2000);
            }
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          } else {
            dispatch(changeTransactionValues("transactionStatus", 7));
            dispatch(changeTransactionValues("gpurchaseFailureMessage", false));
            localStorage.setItem("grouppasslogin", false);
            localStorage.setItem("grouppass", false);
            localStorage.removeItem("grouppassid");
            localStorage.setItem("grouppasscompleted", true);
            dispatch(changeTransactionValues("isOpen", true));
            setTimeout(() => {
              dispatch(fetchTransaction(user, false));
            }, 2000);
          }
        });
    }
  };
};
