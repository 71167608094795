import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Shell,
  Stage,
  DirectorThought,
  FeatureArtists,
  Video,
  Text,
  Button,
  DropInPayment,
  BouncerContextHook,
} from "@StixNext/mojito-ui";

import { theme } from "./../Theme";
import Meg from "../assets/images/Meg.png";
import Stine from "../assets/images/stine.png";
import Tan from "../assets/images/tan.png";
import Ocean from "../assets/images/ocean.png";
import surveyOn from "../assets/images/mobileBanner.gif";
import mobileBanner from "../assets/images/mobileBanner.gif";
import PoojaNansi from "../assets/images/pooja.png";
import main from "../assets/images/main.jpg";
import overview_main from "../assets/images/overview_main.png";
import SwfPdf from "../assets/pdf/swf.pdf";
import Willow from "../assets/images/willow.png";

import Keycloak from "keycloak-js";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";

function HiddenTransaction(props) {
  const value = BouncerContextHook();

  useEffect(() => {
    // props.changeTransactionValues("transactionStatus", 11);
    // props.changeTransactionValues("isOpen", true);
    if (localStorage.getItem("grouppass") == "true") {
      props.changeTransactionValues("hideBuyButton", true);
      if (localStorage.getItem("logout") == "true") {
        props.changeTransactionValues("transactionStatus", 5);
        props.changeTransactionValues("isOpen", true);
      }
      localStorage.setItem("grouppass", false);
    }
    localStorage.setItem("grouppasscompleted", false);
  }, []);
  const [braintreeError, setBraintreeError] = useState({});
  const hanldePayment = (cardPayload) => {
    console.log("card", cardPayload);
    props.checkoutTransaction(
      cardPayload,
      props.loginDetails.user,
      {
        _id: "614ea7a460547700193dc727",
        id: "SL-SWF2021-ERLYBRD",
        title: "Festival Pass",
        description:
          "Access to 85% SWF Programmes including Video-on-demand content",
        expiresOn: "2021-10-08T15:59:00.000Z",
        startSalesDate: "2021-09-02T07:15:00.000Z",
        price: "1",
        sessionIds: "Fest Pass - Early Bird",
        validityInMonths: "1",
        isFixedExpiryDate: "true",
        subTitle: "Early Bird 20% Festival Pass",
        tenant: "SWF2021",
        collection_id: "61436f3e7b6595001a964c63",
        collection: "products",
      },
      true
    );
  };
  useEffect(() => {
    if (!props.loginDetails.authToken && props.loginDetails.accessToken) {
      props.getMerchantAuthToken(1);
    }
  }, [props.loginDetails]);
  const transactionError = (err) => {
    setBraintreeError(err);
  };
  const threeDSecureConfirmation = (payload) => {
    console.log("three d options", payload);
  };
  return (
    <Shell.Body className="shell_body overview_bdy">
      <div className={"alignment"}>
        {braintreeError && (
          <div style={{ color: "red" }}>{braintreeError.message}</div>
        )}
        <div
          className="dropPayment"
          style={{
            width: "100%",
            display: props.transactionDetails.paymentLoader,
          }}
        ></div>
        {Object.keys(props.transactionDetails.amountbcmetadata).length > 0 &&
          props.transactionDetails.amountbcmetadata.totalamount && (
            <DropInPayment
              authorizationToken={props.loginDetails.authToken}
              handlePaymentMethod={hanldePayment}
              onError={transactionError}
              buttonText="Proceed to Payment"
              transactionFailed={
                props.transactionDetails.transactionSuccessFlag
              }
              classProp="dropPayment"
              showConsent={true}
              checkedCheckbox={props.transactionDetails.checkconsent}
              amount={props.transactionDetails.amountbcmetadata.totalamount}
              isThreeDSecure={true}
              threeDSecureConfirmation={threeDSecureConfirmation}
              userInfo={props.loginDetails.user}
              loaderFunction={() => {
                props.changeTransactionValues("loader", "none");
                props.changeTransactionValues("paymentLoader", "block");
              }}
              onChange={(e) => {
                props.changeTransactionValues("checkconsent", e.target.checked);
              }}
            />
          )}
      </div>
    </Shell.Body>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user, product, isThreeDSecure) =>
    dispatch(
      transActions.checkoutTransaction(
        cardPayload,
        user,
        product,
        isThreeDSecure
      )
    ),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HiddenTransaction);
