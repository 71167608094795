import moment from "moment";

const init = {
  venueList: [],
  dateSelected: "",
};

export const Venue = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_VENUE_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
