import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import {
  Column,
  Grid,
  Row,
  Scheduler,
  RightArrowIcon,
  Dropdown,
} from "@StixNext/mojito-ui";
import * as actions from "../actions/Workshop";
import { connect } from "react-redux";
import { displayFilter, formatDateTime, passInfo } from "../utils/utils";
import ComingSoon from "./ComingSoon";
import Workshop_coming_soon from "../assets/images/workshop_coming_soon.gif";

const sortByProperty = (property) => {
  return (a, b) => {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
};

const options = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const Workshops = (props) => {
  let { fetchWorkshop, workshopList, dateSelected, setDateSelected } = props;

  let defaultChannel = [
    { channelName: "Schedule 1", channelId: 1 },
    { channelName: "Schedule 2", channelId: 2 },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [channel, setChannel] = useState(defaultChannel);
  const [mobchannel, setMobChannel] = useState(defaultChannel);
  const [dateOptions, setDateOptions] = useState([]);
  const [scheduleData, setScheduleData] = useState(workshopList);
  const [slidesEvents, setSlidesEvents] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(
    defaultChannel[0].channelId
  );
  const [placeholder, setPlaceholder] = useState("");

  useEffect(() => {
    fetchWorkshop();
    getPlaceholder();
  }, []);

  useEffect(() => {
    if (workshopList.length != 0) {
      console.log("list", workshopList);
      let dates = workshopList.map((programmes) => programmes.date);
      let uniqueDates = dates.sort().filter((v, i, a) => a.indexOf(v) === i);
      uniqueDates = uniqueDates.map((date) => ({ text: date, value: date }));
      console.log("date", dateSelected);
      if (!dateSelected) {
        setScheduleFilter(uniqueDates[0].value);
      }
      setDateOptions(uniqueDates);
    }
  }, [workshopList]);

  useEffect(() => {
    handleChannelView();
  }, [selectedChannel]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  const handleChannelView = () => {
    let filterChannel = defaultChannel.filter(
      (item) => item.channelId == selectedChannel
    );
    setMobChannel(filterChannel);
  };

  let timeLine = [
    { time: "10:00", endTime: "1100", startTime: "1000" },
    { time: "11:00", endTime: "1200", startTime: "1100" },
    { time: "12:00", endTime: "1300", startTime: "1200" },
    { time: "13:00", endTime: "1400", startTime: "1300" },
    { time: "14:00", endTime: "1500", startTime: "1400" },
    { time: "15:00", endTime: "1600", startTime: "1500" },
    { time: "16:00", endTime: "1700", startTime: "1600" },
    { time: "17:00", endTime: "1800", startTime: "1700" },
    { time: "18:00", endTime: "1900", startTime: "1800" },
    { time: "19:00", endTime: "2000", startTime: "1900" },
    { time: "20:00", endTime: "2100", startTime: "2000" },
    { time: "21:00", endTime: "2200", startTime: "2100" },
    { time: "22:00", endTime: "2300", startTime: "2200" },
    { time: "23:00", endTime: "2400", startTime: "2300" },
  ];

  const setScheduleFilter = (date) => {
    let data = workshopList
      .sort(sortByProperty("date"))
      .filter((item) => item.date == date);
    setScheduleData(data);
    setSlidesEvents(data);
    setDateSelected(date);
  };

  useEffect(() => {
    setScheduleFilter(dateSelected);
  }, [dateSelected]);

  const slidesPresented = () => {
    if (window.innerWidth <= 760) {
      return 1;
    } else if (window.innerWidth <= 1366) {
      return 2;
    }
    return 3;
  };

  const handleClick = (eventId) => {
    let event = slidesEvents.filter((item) => eventId == item.id);
    if (event.length == 1) {
      handleRedirect(event[0]);
    }
  };

  const handleRedirect = (event) => {
    const win = window.open(event.beURL, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const getPlaceholder = async () => {
    const val = await actions.fetchWsPlaceholder();
    setPlaceholder(val);
  };

  if (slidesEvents.length == 0 && placeholder) {
    let image;
    if (placeholder.length == 0) {
      image = Workshop_coming_soon;
    } else {
      let imageArray = placeholder[0].placeholderImage
        ? placeholder[0].placeholderImage.split(",")
        : "";
      image = imageArray ? imageArray[1] : "";
    }
    return <ComingSoon image={image} />;
  }

  return (
    <>
      {slidesEvents.length > 0 && (
        <Shell.Body className={"css-work-shop"}>
          {passInfo('Workshop')}
          <>
            {slidesEvents.length > 0 && (
              <Grid>
                <Row className="scheduler-class">
                  <Column xl={12}>
                    <div id="dropdown_123" style={{ display: "flex" }}>
                      <h1
                        style={{
                          paddingTop: 6,
                          margin: "0px 15px 15px 15px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                        className="schedule-title"
                      >
                        SCHEDULE
                      </h1>
                      <div>
                        <Dropdown
                          id="dropdown"
                          options={dateOptions}
                          mulitple={false}
                          Width={250}
                          value={dateSelected}
                          placeholder="Select a Date"
                          onChange={(item) => {
                            setDateSelected(item.value);
                          }}
                        />
                        <Dropdown
                          id="dropdown_channel"
                          options={defaultChannel.map((channel, index) => {
                            return {
                              text: channel.channelName,
                              value: channel.channelId,
                            };
                          })}
                          mulitple={false}
                          value={selectedChannel}
                          placeholder="Select a channel"
                          onChange={(item) => setSelectedChannel(item.value)}
                        />
                      </div>
                    </div>
                  </Column>
                </Row>
                <Row>
                  <Carousel
                    autoPlay={false}
                    interval={5000}
                    slidesPresented={slidesPresented()}
                    Indictor={windowWidth > 760 ? true : false}
                    DotIndictor={windowWidth > 760 ? true : false}
                    MaxIndictor={slidesEvents.length}
                    className={"carousel"}
                  >
                    {slidesEvents
                      .sort(sortByProperty("startTime"))
                      .map((slide, index) => {
                        let imageArray = slide.thumbNail
                          ? slide.thumbNail.split(",")
                          : "";
                        let image = imageArray ? imageArray[1] : "";
                        return (
                          <Card
                            key={index}
                            className={"carousel-slides"}
                            style={{ width: `${100 / slidesPresented()}%` }}
                            onClick={() => handleRedirect(slide)}
                            noPadding={false}
                            noBoxShadow={false}
                            noBorder={true}
                            noBorderRadius={true}
                          >
                            <img src={image} alt="" />
                            <div>
                              <div
                                className={"title-tiles vod-tiles"}
                                dangerouslySetInnerHTML={{
                                  __html: slide?.title,
                                }}
                              />
                              <p as="p" style={{ fontSize: "16px" }}>
                                {"Premieres: " +
                                  formatDateTime(slide.startDateTime)}{" "}
                              </p>
                            </div>
                          </Card>
                        );
                      })}
                  </Carousel>
                </Row>
              </Grid>
            )}
            <Grid>
              <Row>
                <Column xl={12}>
                  <Scheduler
                    className="web_schedule"
                    scheduleData={scheduleData}
                    timeLine={timeLine}
                    channel={channel}
                    eventOnClick={handleClick}
                    titleKey={"title"}
                    subTitleKey={"featuredPresenters"}
                    defultTextButton={"Live Q&A "}
                  ></Scheduler>
                  <Scheduler
                    className="mob_invenue_schedule"
                    scheduleData={scheduleData}
                    timeLine={timeLine}
                    channel={mobchannel}
                    eventOnClick={handleClick}
                    titleKey={"title"}
                    subTitleKey={"featuredPresenters"}
                    defultTextButton={"Live Q&A "}
                  ></Scheduler>
                </Column>
              </Row>
            </Grid>
          </>
        </Shell.Body>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    workshopList: displayFilter(state.Workshop.workshopList),
    dateSelected: state.Workshop.dateSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchWorkshop: () => dispatch(actions.fetchWorkshop()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workshops);
