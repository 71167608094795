import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button, DropInPayment, RadioButton } from "@StixNext/mojito-ui";

import main from "../../assets/images/main.jpg";
import overview_main from "../../assets/images/overview_main.jpg";
import loading from "../../assets/images/loading.gif";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as prodActions from "../../actions/Products";
import ReactHtmlParser from "react-html-parser";

function BuyProduct(props) {
  useEffect(() => {
    props.fetchProducts();
  }, []);

  return (
    <>
      <div
        className="new_modal"
        style={{
          padding: "10px 0px",
        }}
      >
        <Grid>
          <Row>
            <Column xl={5} xs={12}>
              <img className="main_image" src={overview_main} alt="icon" />
            </Column>
            <Column
              xl={7}
              xs={12}
              className={"payment-section-text"}
              style={{ flexFlow: "column", alignItems: "flex-start" }}
            >
              <Row>
                {/* <Text as="h4" className="pass_head">
                  Please select your Festival Pass
                </Text> */}
                <Text as="h4" className="pass_head">
                  Video-On-Demand passes now Available 
                </Text>
              </Row>
              <Row>
                <Text as="p" className="pass_head_para">
                Note: The ticket prices below excludes a per ticket Booking Fee of S$4 (for tickets above S$40); S$3 (for tickets between S$20.01 - S$40) and S$1 (for tickets S$20 and below). Prices include GST.
                </Text>
              </Row>
              <Row>
                <div
                  className={
                    props.productDetails.productLoading
                      ? "loader-product-show"
                      : "loader-pro"
                  }
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "190px", height: "auto" }}
                      src={loading}
                      alt="Avatar"
                    />
                  </div>
                </div>
              </Row>
              {props.productDetails.productList &&
              props.productDetails.productList.length > 0 &&
              !props.productDetails.productLoading
                ? props.productDetails.productList.map((o, k) => {
                    return (
                      <Row className="product_section" key={k}>
                        <Column xl={9} className="radio_colummn">
                          <div className={`radio_btn_clr`}>
                            <RadioButton
                              labelText={o.title}
                              value={o.price}
                              name="radio"
                              id={o.id}
                              onChange={(e) => {
                                var selectedProduct =
                                  props.productDetails.productList.filter(
                                    function (item) {
                                      return item.id === e.target.id;
                                    }
                                  );
                                props.changeProductValues(
                                  "selectedProduct",
                                  selectedProduct[0]
                                );
                                props.changeTransactionValues(
                                  "productPrice",
                                  e.target.value
                                );
                              }}
                            >
                              {o.subTitle ? (
                                <span className="info_section">
                                  (
                                  {ReactHtmlParser(
                                    o.subTitle.replace(
                                      /(?:\r\n|\r|\n)/g,
                                      "<br />"
                                    )
                                  )}
                                  )
                                </span>
                              ) : null}
                              {o.description ? (
                                <p className="buy_now_descrip">
                                  {ReactHtmlParser(
                                    o.description.replace(
                                      /(?:\r\n|\r|\n)/g,
                                      "<br />"
                                    )
                                  )}
                                </p>
                              ) : null}
                            </RadioButton>
                          </div>
                        </Column>
                        <Column xl={2} sm={3} className="approx_colummn">
                          <Text as="h6">
                            <strong>{`S$${o.price}.00`}</strong>
                          </Text>
                          {/* <span style={{ fontSize: "12px" }}>
                        (approx. USD{Number(o.usdapprox).toFixed(2)})
                      </span> */}
                        </Column>
                      </Row>
                    );

                  })
                : !props.productDetails.productLoading && (
                    <Row className="no_prod_row">
                      <Text as="h4" className="no_product_head">
                        All products have been sold out!!
                      </Text>
                    </Row>
                  )}
              <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                <div className="buy_now">
                  <Button
                    style={{ width: "100%" }}
                    primary
                    disabled={
                      props.transactionDetails.productPrice ? false : true
                    }
                    onClick={(e) => {
                      if (
                        !props.loginDetails.authToken &&
                        props.loginDetails.accessToken
                      ) {
                        props.getAuthToken();
                      }
                      props.changeTransactionValues("loader", "block");
                      props.changeTransactionValues("paymentLoader", "none");
                      props.changeTransactionValues("transactionStatus", 2);
                    }}
                  >
                    Buy Now
                  </Button>
                </div>
              </Row>
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, clicked) =>
    dispatch(transActions.fetchTransaction(data, clicked)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyProduct);
