import React, { useState, useEffect } from "react";
import { Modal, Grid, Row, Column, Text } from "@StixNext/mojito-ui";
import main from "../assets/images/main.jpg";
import TransactionBody from "./Transaction/index";

export const TransactionStatus = (props) => {
  const [transactionSuccessFlag, setTransactionSuccessFlag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    setIsOpen(props.isOpen);
    setTransactionSuccessFlag(props.transactionSuccessFlag);
    setTransactionData(props.transactionData);
    setKeycloak(props.username);
  }, [props]);

  return (
    <Modal isOpen={isOpen} size="large" onClose={props.onClose}>
      <Modal.Body
        className={
          props.transactionStatus === 6 ? "grouppass_success_modal" : ""
        }
      >
        <TransactionBody />
      </Modal.Body>
    </Modal>
  );
};
