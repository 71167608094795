import React from "react";
import { Text, Column, Row } from "@StixNext/mojito-ui";

// const FooterBanner = () => {
//   return (
//     <div
//       style={{
//         backgroundColor: "#fff",
//       }}
//     >
//       <div className="footerBanner">
//         <p>Powered by 1 play sports in collaboration with Sports SG</p>
//       </div>
//     </div>
//   );
// };

const FooterLink = () => {
  const footerlinks = [
    {
      text: "Privacy Policy | ",
      href: "https://www.sistic.com.sg/privacy-policy",
    },
    {
      text: "Terms & Conditions | ",
      href: "https://www.sistic.com.sg/terms-and-conditions",
    },
    { text: "FAQ", href: "https://www.sistic.com.sg/faq/live-stream" },
  ];

  return (
    <>
      {footerlinks.map((link, i) => (
        <a target="_blank" rel="noreferrer" href={link.href} key={i}>
          <Text as="captionblock" className="footer_text">
            {link.text}
          </Text>
        </a>
      ))}
    </>
  );
};

export const FooterContent = () => {
  const footerContent = [
    { type: "text", content: "Copyright 1998 - 2021. © SISTIC.com Pte Ltd" },
    { type: "component", content: FooterLink },
    { type: "text", content: "SISTIC Hotline: +65 63485555" },
  ];

  return (
    <Row>
      {footerContent.map((footerData, i) => {
        return (
          <Column xl={4} xs={12} key={i} className="footer_div">
            <div style={{ textAlign: "center" }}>
              {footerData.type === "text" ? (
                <Text as="captionblock" className="footer_text">
                  {footerData.content}
                </Text>
              ) : (
                <footerData.content />
              )}
            </div>
          </Column>
        );
      })}
    </Row>
  );
};

export default FooterLink;
