import React, { Suspense, useEffect, useState } from "react";
import { ThemeProvider } from "emotion-theming";
import { BaseStyles } from "@StixNext/mojito-ui";
import Routes from "./routes";
import { theme } from "./Theme";
import "./App.css";
// Import root component
import { Provider } from "react-redux";
import { store } from "./stores/configureStore";
import TagManager from "react-gtm-module";
import { isIE } from "react-device-detect";
import { IncompatibleBrowser } from "./components/IncompatibleBrowser";

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GTMID}`,
};
TagManager.initialize(tagManagerArgs);
// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div className="Loadercenter">
      <div className="loaderContent">
        <div className="loaderImg"></div>
        {/* <img src={main1} className="loaderImg"/> */}
        <div className="spinner">
          <span className="LoadingText">Loading</span>
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    </div>
  </div>
);

const BaseApp = () => {
  /*   useEffect(() => {
     const script1 = document.createElement("script");
     var att = document.createAttribute("data-queueit-c");
     att.value = "sistic";
     script1.src = "//static.queue-it.net/script/queueconfigloader.min.js";
     script1.setAttributeNode(att);
     script1.type = "text/javascript";
     const script2 = document.createElement("script");
     script2.src = "//static.queue-it.net/script/queueclient.min.js";
     script2.type = "text/javascript";
     document.head.appendChild(script2);
     document.head.appendChild(script1);

     return () => {
       document.head.removeChild(script1);
       document.head.removeChild(script2);
     };
   }, []);
*/
  console.log("is", isIE);
  const [isOpen, setIsOpen] = useState(isIE ? true : false);
  let onClose = () => {
    setIsOpen(false);
  };
  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme.mojito}>
        <BaseStyles />
        <Provider store={store}>
          <div className="App">
            {isIE ? (
              <IncompatibleBrowser isOpen={isOpen} onClose={onClose} />
            ) : (
              <Routes />
            )}
          </div>
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
};

BaseApp.displayName = "BaseApp";
export default BaseApp;
