import { combineReducers } from "redux";
import { Login } from "./Login";
import { Transaction } from "./Transaction";
import { Products } from "./Products";
import { Programmes } from "./Programmes";
import { Workshop } from "./Workshop";
import { Venue } from "./Venue";
import { VideoOnDemand } from "./VideoOnDemand";
import { Festival } from "./Festival";

export default combineReducers({
  Login,
  Transaction,
  Products,
  Programmes,
  Workshop,
  Venue,
  VideoOnDemand,
  Festival
});
