import { api } from "./commonapi/apiPaths";
import { getSchedulerData } from "../utils/utils";

export const changeVenueValues = (field, value) => {
  return {
    type: "UPDATE_VENUE_VALUE",
    field,
    value,
  };
};

export const fetchVenue = () => {
  return (dispatch) => {
    return fetch(api.fetchInVenueProgrames)
      .then((data) => data.json())
      .then((value) => {
        let programList = getSchedulerData(value.data, "invenue");
        dispatch(changeVenueValues("venueList", programList));
      })
      .catch((err) => {});
  };
};

export const fetchIvpPlaceholder = async () => {
  let details  = await fetch(api.fetchIvpPlacehorder)
    .then((data) => data.json())
    .then(value => value.data)
    .catch((err) => {});
    return details 
}

export const setDateSelected = (date) => {
  return (dispatch) => {
    dispatch(changeVenueValues("dateSelected", date));
  };
};
