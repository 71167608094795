import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Shell,
  VideoPlayer,
  FeatureArtists,
  Grid,
  Row,
  Column,
  Text,
  Button,
  BouncerContextHook,
  CloseWhiteIcon,
  DownArrowIcon,
  SisticLivePlayerAlt,
  EnterPin,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import Moment from "react-moment";
import Zadie from "../assets/images/Zadie.png";
import Book from "../assets/images/book.png";
import BookStore from "../assets/images/bookstore.jpg";
import MagicBookStore from "../assets/images/magicbookstore.jfif";
import Donation from "../assets/images/donation.png";
import Arrow from "../assets/images/leftarrow.png";
import wrapimage from "../assets/images/wrap_bgimage.jpg";
import qrcodeimage from "../assets/images/qrcodeimage.png";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as Festival from "../actions/Festival";

function VideoDetail(props) {
  const loginContext = BouncerContextHook();
  const [ChatOrQA, setChatOrQA] = useState("chat");
  const [play, setPlay] = useState(false);
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(false);

  let interval = null;
  function myFunction(startDateTime, endDateTime) {
    interval = setInterval(function () {
      var serverDate = moment(new Date(moment.utc().format()));
      var indiaDate = moment(new Date(moment.utc(startDateTime).format()));
      setStart(true);
      if (indiaDate.diff(serverDate, "seconds") < 1) {
        setPlay(true);
        clearInterval(interval);
      }
    }, 1000);
  }
  // let onClose = () => {
  //   props.changeTransactionValues("productPrice", 0);
  //   props.changeTransactionValues("showDropinComponent", false);
  //   props.changeTransactionValues("transactionStatus", 1);
  //   props.changeTransactionValues("checkconsent", false);
  //   props.changeTransactionValues("isOpen", false);
  //   localStorage.setItem("buttonClicked", false);
  // };

  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
  };

  useEffect(() => {
    let progammeId = props.match.params.id;
    let passType = props.match.path.includes("festivalpassplus")
      ? "Festival Pass Plus"
      : "Festival Pass";
    passType = props.match.path.includes("workshops") ? "Workshops" : passType;
    props.fetchProgrammeDetail(progammeId, passType);
    return () => {
      clearInterval(interval);
      props.changeProgrammesValues("programme", []);
    };
  }, []);
  useEffect(() => {
    if (props.programmeDetails.programme.length > 0) {
      myFunction(
        props.programmeDetails.programme[0].startDateTimeUTC,
        props.programmeDetails.programme[0].endDateTimeUTC
      );
      if (
        props.programmeDetails.programme[0].ChatFlag &&
        props.programmeDetails.programme[0].QAFlag
      ) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].ChatFlag) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].QAFlag) {
        setChatOrQA("QA");
      }
    }
  }, [props.programmeDetails.programme]);
  const noPass = () => {
    return props.transactionDetails.passName !== "VOD PASS HOLDER"
      ? true
      : false;
  };

  const createPin = () => {
    props.changeTransactionValues("isOpen", true);
    props.changeTransactionValues("transactionStatus", 11);
  };

  return (
    <>
      {props.programmeDetails.programme.length > 0 &&
        props.programmeDetails.programme.map((value, key) => {
          return (
            <div className="row vodContainer adjustment">
              <div className="container">
                <Link
                  to={
                    localStorage.getItem("prevPath")
                      ? localStorage.getItem("prevPath")
                      : "/"
                  }
                  className="title"
                >
                  <img src={Arrow} alt="icon" />
                  <Text as="captionblock" className="titletext">
                    {ReactHtmlParser(
                      value.title.replace(/(?:\r\n|\r|\n)/g, "<br />")
                    )}
                    <div>
                      <Moment format="LT">{value.startTime}</Moment> -{" "}
                      <Moment format="LT">{value.displayEndTime}</Moment>
                    </div>
                  </Text>
                </Link>
                <div className="wholediv">
                  <div className="videodiv disablechat">
                    {props.loginDetails.authenticated &&
                      props.transactionDetails.hideBuyButton &&
                      noPass() === true && (
                        <>
                          <EnterPin
                            domain={
                              process.env.REACT_APP_NODE_ENV == "development"
                                ? process.env.REACT_APP_TRANSACTION_API_DOMAIN
                                : ""
                            }
                            loginDetails={props.loginDetails}
                            isR21={value.r21}
                            createPin={createPin}
                          >
                            <SisticLivePlayerAlt
                              className="video-player"
                              playerItems={{
                                src: value.videoURL,
                                stopChat:
                                  value.chatFlag || value.qnaFlag
                                    ? false
                                    : true,
                                chatUrl: " ",
                                enableChat: value.chatFlag,
                                chatContent: (
                                  <Iframe
                                    url={
                                      process.env.PUBLIC_URL +
                                      `/chat.html?${value.chatSessionId}`
                                    }
                                    width="100%"
                                    height="413px"
                                    id="myId"
                                    className="pigeonhole-iframe"
                                    display="initial"
                                    position="relative"
                                  />
                                ),
                                enableQA: value.qnaFlag,
                                qaContent: (
                                  <Iframe
                                    url={
                                      process.env.PUBLIC_URL +
                                      `/qa.html?${value.qaSessionId}`
                                    }
                                    width="100%"
                                    height="413px"
                                    id="myId"
                                    className="pigeonhole-iframe"
                                    display="initial"
                                    position="relative"
                                  />
                                ),
                              }}
                            />
                          </EnterPin>
                        </>
                      )}
                    {!play && start && (
                      <div
                        className={`text_div ${
                          value.ChatFlag || value.QAFlag ? "" : "disablechat"
                        }`}
                      >
                        <p>
                          Livestream will start at
                          <br />
                          <Moment format="DD MMM YYYY">
                            {value.startDateTime}
                          </Moment>{" "}
                          | <Moment format="LT">{value.startDateTime}</Moment>{" "}
                          SGT
                        </p>
                      </div>
                    )}
                    {props.loginDetails.authenticated === null ||
                    props.loginDetails.authenticated === false ||
                    props.transactionDetails.hideBuyButton === false ||
                    noPass() === false ? (
                      <div
                        className="wrap_div"
                        style={{
                          backgroundImage: `url(${wrapimage})`,
                        }}
                      >
                        <Text as="h4" className="wrap_title">
                          This programme is only accessible with a Festival Pass
                          Or a Festival Pass Plus.
                        </Text>
                        <div>
                          <Text as="h6" className="wrap_pass">
                            If you've purchased one, please log in with your
                            SISTIC account to view the programme. Get your
                            Video-On-Demand Pass from 18 Nov onwards.
                          </Text>
                          {/* <Button
                            className="buy_now_button"
                            primary
                            style={{ width: "332px", height: "46px" }}
                            onClick={(e) => {
                              getDegitalPass(e);
                            }}
                          >
                            Buy Now
                          </Button> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="synopiscontainer">
                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="p" className="synopsis">
                      Synopsis
                    </Text>
                    <Text as="p">
                      {ReactHtmlParser(
                        value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                      )}
                    </Text>
                    <div className="bookwebsection">
                      <div className="booksection">
                        <a
                          href={"https://swfbooks.com"}
                          target="_blank"
                          style={{
                            display: "flex",
                            textDecoration: "none",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={MagicBookStore}
                            alt="icon"
                            className="bookstore-img"
                          />
                          <div>
                            <Column>
                              <Text as="p">Visit</Text>
                              <Text as="p">swfbooks.com</Text>
                            </Column>
                          </div>
                        </a>
                        <div className="booksection">
                          <div className="qrcodesection">
                            <img
                              src={qrcodeimage}
                              alt="icon"
                              className="bookstore-img"
                            />
                            <div>
                              <Column>
                                <Text as="p">
                                  <strong>
                                    SCAN TO SUBMIT YOUR FAVOURITE QUOTES
                                  </strong>
                                </Text>
                                <Text as="p">
                                  <strong>
                                    and watch @ahhuat_SWF turn them into poetry
                                    gold on Twitter
                                  </strong>
                                </Text>
                              </Column>
                            </div>
                          </div>
                        </div>
                        {value.donationFlag && (
                          <a
                            href={"https://www.giving.sg/sso"}
                            target="_blank"
                            style={{
                              display: "flex",
                              textDecoration: "none",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={Donation}
                              alt="icon"
                              className="donation-img"
                            />
                            <div className="giving-link">
                              <Column className="booktext">
                                <Text as="p" className="donation-link">
                                  Click here to donate <br /> to our giving.sg{" "}
                                </Text>
                              </Column>
                            </div>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="chatsection">
                    <div className="videoDetailFeature">
                      <Text as="p">
                        {value.featuredPresenters &&
                          ReactHtmlParser(
                            value.featuredPresenters.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br />"
                            )
                          )}
                      </Text>
                      {value.bookletURL ? (
                        <a
                          href={value.bookletURL}
                          className="digital-booklet-link"
                          target="#"
                        >
                          <Button className="detail_btn">
                            View Digital Programme Booklet
                          </Button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="videoDetailFeature">
                      <Text as="h6">
                        <b>Moderator</b>
                      </Text>
                      <Text as="p">
                        {value.moderator &&
                          ReactHtmlParser(
                            value.moderator.replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                      </Text>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Festival,
    productDetails: state.Products,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(Festival.changeProgrammesValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  // fetchProducts: (programmeId, passType) =>
  //   dispatch(prodActions.fetchProducts(programmeId, passType)),
  // fetchCheckProducts: (programmeId, passType) =>
  //   dispatch(prodActions.fetchCheckProducts(programmeId, passType)),
  fetchProgrammeDetail: (id, passType) =>
    dispatch(Festival.fetchProgrammeDetail(id, passType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetail);
