import React, { useEffect, useState } from "react";
import { Shell, Scheduler, RightArrowIcon } from "@StixNext/mojito-ui";
import { Grid, Row, Column, Dropdown } from "@StixNext/mojito-ui";
import { connect } from "react-redux";
import * as actions from "../actions/Venue";
import workshop from "../assets/images/workshop.png";
import radio_web from "../assets/images/radio_web.gif";
import radio_mob from "../assets/images/radio_mob.jpg";
import { displayFilter, passInfo } from "../utils/utils";
import ComingSoon from "./ComingSoon";
import In_venue_prog_coming_soon from "../assets/images/in_venue_prog_coming_soon.gif";

const sortByProperty = (property) => {
  return (a, b) => {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
};

const options = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const InVenueProgrammes = (props) => {
  let { fetchWorkshop, workshopList, dateSelected, setDateSelected } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState([]);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  let defultChannel = [
    { channelName: "Malay Heritage Centre Auditorium", channelId: 1 },
    { channelName: "ProjectorX Riverside Neon Room", channelId: 2 },
    { channelName: "The Arts House Chamber", channelId: 3 },
    { channelName: "The Arts House Play Den", channelId: 5 },
    { channelName: "The Fabulous Baker Boy", channelId: 6 },
    { channelName: "The Projector", channelId: 7 },
    { channelName: "Theatre Practice Rehearsal Studio", channelId: 8 },
    { channelName: "Various Locations", channelId: 9 },
  ];

  const [channel, setChannel] = useState(defultChannel);
  const [mobchannel, setMobChannel] = useState(defultChannel);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(
    defultChannel[0].channelId
  );
  const [scheduleData, setScheduleData] = useState(workshopList);
  const [slidesEvents, setSlidesEvents] = useState([]);
  const [placeholder, setPlaceholder] = useState("");
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    props.fetchWorkshop();
    getPlaceholder();
    setDateSelected("");
  }, []);

  // useEffect(() => {
  //   console.log("workshop", workshopList);
  //   let newList = workshopList.sort(sortByProperty("date"));
  //   let defaultDate = newList && newList[0] && newList[0].date;
  //   console.log("check", defaultDate, dateSelected);
  //   if (defaultDate != dateSelected) {
  //     // if (defaultDate != dateSelected || (!dateSelected && defaultDate)) {
  //     console.log("here");
  //     setDateSelected(defaultDate);
  //   }
  //   console.log("workshop list", workshopList);
  //   // setSlidesEvents(workshopList);
  // }, [workshopList]);

  useEffect(() => {
    handleChannelView();
  }, [selectedChannel]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const handleChannelView = () => {
    let filterChannel = defultChannel.filter(
      (item) => item.channelId == selectedChannel
    );
    let defultValue = defultChannel.length > 0 ? defultChannel[0] : {};
    filterChannel = filterChannel.length == 0 ? [defultValue] : filterChannel;

    setMobChannel(filterChannel);
  };

  let timeLine = [
    { time: "10:00", endTime: "1100", startTime: "1000" },
    { time: "11:00", endTime: "1200", startTime: "1100" },
    { time: "12:00", endTime: "1300", startTime: "1200" },
    { time: "13:00", endTime: "1400", startTime: "1300" },
    { time: "14:00", endTime: "1500", startTime: "1400" },
    { time: "15:00", endTime: "1600", startTime: "1500" },
    { time: "16:00", endTime: "1700", startTime: "1600" },
    { time: "17:00", endTime: "1800", startTime: "1700" },
    { time: "18:00", endTime: "1900", startTime: "1800" },
    { time: "19:00", endTime: "2000", startTime: "1900" },
    { time: "20:00", endTime: "2100", startTime: "2000" },
    { time: "21:00", endTime: "2200", startTime: "2100" },
    { time: "22:00", endTime: "2300", startTime: "2200" },
    { time: "23:00", endTime: "2400", startTime: "2300" },
  ];

  const setScheduleFilter = (date) => {
    console.log("dateinside", date);
    let data = workshopList.sort(sortByProperty("date")).filter((item) => {
      return item.date == date;
    });
    console.log("data", data);
    setScheduleData(data);
    setSlidesEvents(data);
  };

  useEffect(() => {
    let newList = workshopList.sort(sortByProperty("date"));
    let defaultDate = newList && newList[0] && newList[0].date;
    if (defaultDate !== dateSelected) {
      let dates = [];
      let todaySlides = [];
      let today = new Date().toLocaleDateString();
      let selectDate = null;
      workshopList.map((programmes) => {
        programmes["buttonEnabled"] = programmes.QAFlag ? true : false;
        dates.push(programmes.date);
        let programmesDate = new Date(programmes.date).toLocaleDateString();
      });
      if (todaySlides.length != 0) setSlidesEvents(todaySlides);
      let uniqueDates = dates.sort().filter((v, i, a) => a.indexOf(v) === i);
      let a = [];
      let b = [];
      uniqueDates = uniqueDates.map((date) => {
        if (!a.includes(date)) {
          a.push(date);
          b.push({ text: date, value: date });
        }

        return { text: date, value: date };
      });
      setDateOptions(b);

      if (!dateSelected) {
        setDateSelected(defaultDate);
      }
      console.log("workshop list", workshopList);

      let cat = [];
      workshopList.map((video) => {
        let { Category } = video;
        if (Category) {
          cat.push(Category);
        }
      });
      let uniqueCat = cat.sort().filter((v, i, a) => a.indexOf(v) === i);
      setCategory(uniqueCat);
    }
  }, [workshopList]);

  useEffect(() => {
    console.log("date", dateSelected);
    setScheduleFilter(dateSelected);
  }, [dateSelected]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  // useEffect(() => {
  //   let cat = [];
  //   workshopList.map((video) => {
  //     let { Category } = video;
  //     if (Category) {
  //       cat.push(Category);
  //     }
  //   });
  //   let uniqueCat = cat.sort().filter((v, i, a) => a.indexOf(v) === i);
  //   setCategory(uniqueCat);
  // }, [workshopList]);

  const handleClick = (eventId) => {
    let event = slidesEvents.filter((item) => eventId == item.id);
    if (event.length == 1) {
      handleRedirect(event[0]);
    }
  };

  const handleRedirect = (event) => {
    const win = window.open(event.beURL, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const getPlaceholder = async () => {
    const val = await actions.fetchIvpPlaceholder();
    setPlaceholder(val);
  };

  if (scheduleData.length == 0 && placeholder) {
    let image;
    if (placeholder.length == 0) {
      image = In_venue_prog_coming_soon;
    } else {
      let imageArray = placeholder[0].placeholderImage
        ? placeholder[0].placeholderImage.split(",")
        : "";
      image = imageArray ? imageArray[1] : "";
    }
    return <ComingSoon image={image} />;
  }

  return (
    <>
      {scheduleData.length > 0 && (
        <Shell.Body className={"css-work-shop"}>
          {passInfo('In-Venue Programme')}
          <>
            <Grid>
              <Row className="invenue-scheduler-class">
                <Column xl={12}>
                  <div id="invenue_dropdown_123" style={{ display: "flex" }}>
                    <h1
                      style={{
                        paddingTop: 6,
                        margin: "0px 15px 15px 15px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      className="schedule-title"
                    >
                      SCHEDULE
                    </h1>
                    <div className="tab_whole">
                      <Dropdown
                        id="invenue_dropdown"
                        options={dateOptions}
                        mulitple={false}
                        Width={250}
                        value={dateSelected}
                        placeholder="Select a Date"
                        onChange={(item) => {
                          setDateSelected(item.value);
                        }}
                      />
                      <Dropdown
                        id="invenue_dropdown_channel"
                        options={defultChannel.map((channel, index) => {
                          return {
                            text: channel.channelName,
                            value: channel.channelId,
                          };
                        })}
                        mulitple={false}
                        value={selectedChannel}
                        placeholder="Select a channel"
                        onChange={(item) => setSelectedChannel(item.value)}
                      />
                    </div>
                  </div>
                </Column>
              </Row>

              <Row>
                <Column xl={12}>
                  <Scheduler
                    className="web_invenue_schedule"
                    scheduleData={scheduleData}
                    timeLine={timeLine}
                    channel={channel}
                    eventOnClick={handleClick}
                    titleKey={"title"}
                    subTitleKey={"featuredPresenters"}
                    defultTextButton={"Live Q&A "}
                  ></Scheduler>
                  <Scheduler
                    className="mob_invenue_schedule"
                    scheduleData={scheduleData}
                    timeLine={timeLine}
                    channel={mobchannel}
                    eventOnClick={handleClick}
                    titleKey={"title"}
                    subTitleKey={"featuredPresenters"}
                    defultTextButton={"Live Q&A "}
                  ></Scheduler>
                </Column>
              </Row>
            </Grid>

            {/* <Grid>
              <Row>
                <Column>
                  <Text as={"h6"} className="schedule">
                    SCHEDULE
                  </Text>
                </Column>
              </Row>
            </Grid> */}
          </>
        </Shell.Body>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  // let workshopList = displayFilter(state.Venue.venueList)
  return {
    workshopList: displayFilter(state.Venue.venueList),
    dateSelected: state.Venue.dateSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchWorkshop: () => dispatch(actions.fetchVenue()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InVenueProgrammes);
