import moment from "moment";

const init = {
  workshopList: [],
  dateSelected: "",
};

export const Workshop = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_WORKSHOP_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
