import React, { useEffect, useState } from "react";
import {  Shell, Text, CarouselWithScheduler } from "@StixNext/mojito-ui";
import * as actions from "../actions/Festival";
import { connect } from "react-redux";
import { displayFilter, formatDateTime } from "../utils/utils";
import ComingSoon from "./ComingSoon";
import Festival_pass_plus_coming_soon from "../assets/images/festival_pass_plus_coming_soon.gif";

let defaultChannel = [
  { channelName: "Channel 1", channelId: 1 },
  { channelName: "Channel 2", channelId: 2 },
  { channelName: "Channel 3", channelId: 3 },
];


const FestivalPassPlus = (props) => {
  let { festivalPlusItems } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [placeholder, setPlaceholder] = useState("");

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  useEffect(() => {
    props.fetchFestivalPlusPrograms();
    getPlaceholder();
  }, []);

  const handleClick = (value) => {
    props.history.push(`/festivalpassplus/${value.id}`);
  };

  const onCalendarClick = (eventId) => {
    props.history.push(`/festivalpassplus/${eventId}`);
  }

  const getPlaceholder = async () => {
    const val = await actions.fetchFppPlaceholder();
    setPlaceholder(val);
  };

  if (festivalPlusItems.length == 0) {
    if (placeholder) {
      let image;
      if (placeholder.length == 0) {
        image = Festival_pass_plus_coming_soon;
      } else {
        let imageArray = placeholder[0].placeholderImage
          ? placeholder[0].placeholderImage.split(",")
          : "";
        image = imageArray ? imageArray[1] : "";
      }
      return <ComingSoon image={image} />;
    }
    return null
  } 

  const slidesPresented = () => {
    if (windowWidth <= 760) {
      return 1;
    } else if (windowWidth <= 1366) {
      return 2;
    }
    return 3;
  };

  return (
      <Shell.Body className="css-work-shop">
        <div className="pass-info">
          <Text as="p">
            All programmes listed here can be accessed by Festival Pass Plus holders only.
          </Text>
        </div>
        <CarouselWithScheduler
          data={festivalPlusItems}
          onClick={handleClick}
          defaultChannel={defaultChannel}
          onCalendarClick={onCalendarClick}
          slidesPresented={slidesPresented()}
          metaData={[
            {
              key: 'Premieres',
              value: 'startDateTime',
              formatter: formatDateTime
            }
          ]}
        />
      </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  festivalPlusItems: displayFilter(state.Festival.festivalPlusItems),
  dateSelected: state.Festival.dateSelected,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFestivalPlusPrograms: () =>
    dispatch(actions.fetchFestivalPlusPrograms()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalPassPlus);
