import { api } from "./commonapi/apiPaths";
import { getSchedulerData } from "../utils/utils";

export const changeWorkshopValues = (field, value) => {
  return {
    type: "UPDATE_WORKSHOP_VALUE",
    field,
    value,
  };
};

export const fetchWorkshop = () => {
  return (dispatch) => {
    fetch(api.fetchWorkshopProgrames)
      .then((data) => data.json())
      .then((value) => {
        let programList = getSchedulerData(value.data, "workshops");
        dispatch(changeWorkshopValues("workshopList", programList));
      })
      .catch((err) => {});
  };
};

export const fetchWsPlaceholder = async () => {
  let details  = await fetch(api.fetchWsPlacehorder)
    .then((data) => data.json())
    .then(value => value.data)
    .catch((err) => {});
    return details 
}

export const setDateSelected = (date) => {
  return (dispatch) => {
    dispatch(changeWorkshopValues("dateSelected", date));
  };
};
